<template>
  <div>
    <b-row>
      <b-spinner v-if="students.loading" label="Loading...." /> <br/>
      <b-col cols="6">
        <h4>Students with a Selection that references an Application, but the Application doesn't reference the Selection</h4>
        <h6>application.applicationSelectionId === null && selection.selectionApplicationId === application.id </h6>
        <b-row>
          <b-col v-for="student in computedStudents" :key="student.id" cols="12" class="mb-1">
           <b-card>
              <b-card-title class="d-flex justify-content-between">
                <div class="align-content-center">{{ student.name.first }} {{ student.name.last }}</div>
              </b-card-title>

             <b-card v-for="application in student.applications" :key="application.id" class="border-1 border-danger">
               <b-card-title class="d-flex justify-content-between">
                 <div class="align-content-center"></div>
                 <div>
                   <b-button size="sm" class="mr-1" @click="attachSelection(application)">Attach</b-button>
                   <b-button size="sm" @click="removeSelection(application)">Remove</b-button>
                 </div>
               </b-card-title>
               <b-card-text>
                 <pre class="p-1">{{ application }}</pre>
               </b-card-text>
             </b-card>


           </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col v-for="application in computedApplications" :key="application.id" cols="12" class="mb-1">
            <h4>Applications with multiple orphaned Selections</h4>
            <h6>selection.selectionApplicationId === application.id && application.applicationSelectionId !== selection.id</h6>

            <b-overlay :show="deletingOrphans">
             <template #overlay>
               <b-spinner label="Removing Orphans...." />
             </template>
              <template #default>
                <b-card>
                  <b-card-title class="d-flex justify-content-between">
                    <div class="align-content-center">{{ application.student.name.first }} {{ application.student.name.last }}</div>
                    <div>
                      <b-button size="sm" @click="deleteOrphans(application)">Remove Orphans ({{ getOrphans(application).length }})</b-button>
                    </div>
                  </b-card-title>
                  <b-card-text>
                    <pre class="p-1">{{ simpleAppDetails(application) }}</pre>
                  </b-card-text>

                  <b-card v-for="selection in application.selections" :key="selection.id" class="border-1" :class="application.applicationSelectionId === selection.id ? 'border-success' : 'border-danger'">
                    <b-card-text>
                      <pre class="p-1">{{ selection }}</pre>
                    </b-card-text>
                  </b-card>
                </b-card>
              </template>
            </b-overlay>

          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {API, graphqlOperation} from 'aws-amplify';
import {
  listApplications, listSelections, listStudents,
    deleteSelection, updateApplication
} from './queries/selections';
import notify from '@/mixins/notify.mixin';
import popper from '@/mixins/popper.mixin';
import settingsMixin from '@/mixins/settings.mixin';

export default {
  name: 'Export',
  mixins: [notify, popper, settingsMixin],
  data() {
   return {
     deletingOrphans: false,
     applications: {
       items: [],
       loading: true,
     },
     selections: {
       items: [],
       loading: true,
     },
     students: {
       items: [],
       loading: true,
     },
   }
  },
  computed: {
    computedStudents() {
      if(this.students.loading) return []

      const applications = JSON.parse(JSON.stringify(this.applications.items))
      const applicationsMap = applications.reduce((map, application) => {
        const selection = this.selections.items.find(s => s.selectionApplicationId === application.id)
        if(selection) {
          application.selection = JSON.parse(JSON.stringify(selection))
        }
        if (map[application.studentApplicationsId]) {
          map[application.studentApplicationsId].push(application);
        }
        else {
          map[application.studentApplicationsId] = [application];
        }
        return map;
      }, {});

      const students = JSON.parse(JSON.stringify(this.students.items))
      const items = students.map(student => {
        student.applications = applicationsMap[student.id] || []
        return student
      })
      return items.filter(student => student.applications.some(application =>
          application.selection && !application.applicationSelectionId
      ))
    },
    computedApplications() {
      if(this.selections.loading || this.applications.loading) return []
      const selections = JSON.parse(JSON.stringify(this.selections.items))
      const selectionsMap = selections.reduce((map, selection) => {
        if (map[selection.selectionApplicationId]) {
          map[selection.selectionApplicationId].push(selection);
        }
        else {
          map[selection.selectionApplicationId] = [selection];
        }
        return map;
      }, {});

      const applications = JSON.parse(JSON.stringify(this.applications.items))
      const items = applications.map(application => {
        application.student = this.students.items.find(student => student.id === application.studentApplicationsId)
        application.selections = selectionsMap[application.id] || []
        application.loading = false
        return application
      })
      return items.filter(application => application.selections.length > 1)
    },
  },
  async mounted() {
    await this.listSelections()
    await this.listApplications()
    await this.listStudents()
  },
  methods: {
    async listApplications(nextToken, pagedApplications) {
      this.applications.loading = true
      const applications = pagedApplications || []
      const input = {
        limit: 500,
        nextToken: nextToken,
        filter: {
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        }
      }

      const response = await API.graphql(graphqlOperation(listApplications, input));
      applications.push(...response.data.listApplications.items)

      if(response.data.listApplications.nextToken) {
        console.log('nextToken', response.data.listApplications.nextToken)
        await this.listApplications(response.data.listApplications.nextToken, applications)
      }
      else {
        this.applications.items = applications
        this.applications.loading = false
      }
    },
    async listSelections(nextToken, pagedItems) {
      this.selections.loading = true
      const items = pagedItems || []
      const input = {
        limit: 500,
        nextToken: nextToken,
        filter: {
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        }
      }

      const response = await API.graphql(graphqlOperation(listSelections, input));
      items.push(...response.data.listSelections.items)

      if(response.data.listSelections.nextToken) {
        console.log('nextToken', response.data.listSelections.nextToken)
        await this.listSelections(response.data.listSelections.nextToken, items)
      }
      else {
        this.selections.items = items
        this.selections.loading = false
      }
    },
    async listStudents(nextToken, pagedItems) {
      this.students.loading = true
      const items = pagedItems || []
      const input = {
        limit: 500,
        nextToken: nextToken,
        filter: {
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        }
      }

      const response = await API.graphql(graphqlOperation(listStudents, input));
      items.push(...response.data.listStudents.items)

      if(response.data.listStudents.nextToken) {
        console.log('nextToken', response.data.listStudents.nextToken)
        await this.listStudents(response.data.listStudents.nextToken, items)
      }
      else {
        this.students.items = items
        this.students.loading = false
      }
    },


    async deleteOrphans(application) {
      this.deletingOrphans = true;

      const selections = this.getOrphans(application);
      console.log('deleting orphans', selections)


      await Promise.all(selections.map(async (selection) => {
        const input = { id: selection.id }
        console.log('deleting', selection)
        await API.graphql(graphqlOperation(deleteSelection, { input: input }));
      }));

      // After deleting orphans, filter out deleted items from selections
      this.selections.items = this.selections.items.filter(item =>
          !selections.map(s => s.id).includes(item.id)
      );
      this.deletingOrphans = false;
    },


    async attachSelection(application) {
      if (!application.applicationSelectionId && application.selection?.selectionApplicationId) {
        const input = {
          id: application.id,
          applicationSelectionId: application.selection.id
        }
        await API.graphql(graphqlOperation(updateApplication, { input: input }));
        const updatedApplication = this.applications.items.find(a => a.id === application.id)
        updatedApplication.applicationSelectionId = application.selection.id
      }
    },

    async removeSelection(application) {
      if (!application.applicationSelectionId && application.selection?.selectionApplicationId) {
        const input = {
          id: application.selection.id,
        }
        await API.graphql(graphqlOperation(deleteSelection, { input: input }));
        this.selections.items = this.selections.items.filter(s => s.id !== application.selection.id)
      }
    },




    getOrphans(application) {
      return application.selections.filter(selection => selection.id !== application.applicationSelectionId)
    },

    simpleAppDetails(application) {
      return {
        id: application.id,
        applicationFestivalId: application.applicationFestivalId,
        applicationFormId: application.applicationFormId,
        applicationInstrumentId: application.applicationInstrumentId,
        applicationSelectionId: application.applicationSelectionId,
        teacherID: application.teacherID,
        studentApplicationsId: application.studentApplicationsId,
        student: application.student,
      }
    }
  }
}
</script>

<style scoped>

</style>
