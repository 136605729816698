/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listApplications = /* GraphQL */ `
  query ListApplications(
    $id: ID
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listApplications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        teacherID
        createdAt
        updatedAt
        studentApplicationsId
        applicationFestivalId
        applicationFormId
        applicationInstrumentId
        applicationSelectionId
      }
      nextToken
    }
  }
`;
export const listSelections = /* GraphQL */ `
  query ListSelections(
    $id: ID
    $filter: ModelSelectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSelections(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        part
        ranking {
          state
        }
        accepted
        state {
          order
        }
        createdAt
        updatedAt
        selectionApplicationId
        selectionEnsembleId
      }
      nextToken
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $key: String
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSettings(
      key: $key
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
        value
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listStudents = /* GraphQL */ `
  query ListStudents(
    $id: ID
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name {
          first
          last
          preferred
        }
      }
      nextToken
    }
  }
`;

export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteSelection = /* GraphQL */ `
  mutation DeleteSelection(
    $input: DeleteSelectionInput!
    $condition: ModelSelectionConditionInput
  ) {
    deleteSelection(input: $input, condition: $condition) {
      id
    }
  }
`;


